import React, { ReactNode, useState } from 'react';
import AppBarSimple from '../AppBarSimple';

import styled from 'styled-components';
import useEventListener from '../../hooks/useEventListener';

export type Props = {
  menu: ReactNode;
  appBarTitle: string;
  children: ReactNode;
  appBarRightContent?: ReactNode;
  isLoading?: boolean;
};

function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const THRESHOLD_WIDTH = 1000;

const AppBarWithMenuTemplate = ({
  menu,
  appBarTitle,
  children,
  appBarRightContent,
  isLoading,
}: Props) => {
  const [windowSize, setWindowSize] = useState(getSize());
  useEventListener('resize', () => setWindowSize(getSize()));
  const isDesktopView = windowSize.width >= THRESHOLD_WIDTH;
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <Page>
      {isDesktopView && <StickyMenu>{menu}</StickyMenu>}
      {(!isDesktopView && isMenuOpen) && <FloatingMenuMenu isOpen={isMenuOpen} menuClose={() => setMenuOpen(false)}>{menu}</FloatingMenuMenu>}

      <RightSide isDesktopView={isDesktopView}>
        <Sticky>
          <AppBarSimple
            title={appBarTitle}
            showMenu={!isDesktopView}
            isLoading={isLoading}
            rightContent={appBarRightContent}
            onMenuClick={() => setMenuOpen(true)}
          />
        </Sticky>
        {children}
      </RightSide>
    </Page>
  );
};

export default AppBarWithMenuTemplate;

const FloatingMenuMenu = ({ children, menuClose }: { children: ReactNode, menuClose():void, isOpen:boolean }) => (
  <BackDrop onClick={menuClose}>
    <FloatingMenuDiv >{children}</FloatingMenuDiv>
  </BackDrop>
);

const StickyMenu = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 300px;
`;
const Page = styled.div``;

const RightSide = styled.div<{ isDesktopView: boolean }>`
  flex: 1;
  margin-left: ${(p) => (p.isDesktopView ? '300px' : '0')};
`;
const Sticky = styled.div`
  position: sticky;
  top: 0;
`;

const FloatingMenuDiv = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width:300px;
  transition: width .3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const BackDrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  position: fixed;
  justify-content: center;
  background-color: #00000066;
  -webkit-tap-highlight-color: transparent;
  
`;
