import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { MenuIcon } from '../Icons/MainIcons';
import IconButton from '../IconButton/IconButton';
import useEventListener from '../../hooks/useEventListener';
import LinearProgress from '../LinearProgress/LinearProgress';

export type Props = {
  rightContent?: ReactNode;
  title: string;
  onMenuClick?: Function;
  showMenu?: boolean;
  isLoading?: boolean;
};

const AppBarSimple = ({
  rightContent,
  title,
  onMenuClick,
  isLoading=false,
  showMenu = true,
}: Props) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEventListener('scroll', () => {
    setIsScrolled(window.pageYOffset > 0);
  });

  return (
    <>
      <Container isScrolled={isScrolled} isLoading={isLoading} showMenu={showMenu}>
        <Left>
          {showMenu && (
            <IconButton onClick={() => onMenuClick?.()}>
              <MenuIcon/>
            </IconButton>
          )}
          <label title={title}>{title}</label>
        </Left>
        {rightContent}
        <RightSpace />
      </Container>
     {isLoading && <LinearProgress />}
    </>
  );
};

export default AppBarSimple;

type StyledDivProps = {
  isScrolled: boolean;
  isLoading:boolean;
  showMenu:boolean;
};

const RightSpace = styled.div`
  width: 12px;
`;

const Container = styled.div<StyledDivProps>`
  background: #002d74;
  box-shadow: ${(props) =>
    props.isScrolled
      ? '0px 1px 2px 0px rgba(60,64,67,.3),0px 1px 3px 1px rgba(60,64,67,.15)'
      : 'none'};
  height: 64px;
  padding: 0 1.5em 0 ${p => p.showMenu ? ".5em" : "1.5em"};
  transform: translateZ(0);
  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.15s cubic-bezier(0.4, 0, 1, 1);
  display: flex;
  align-items: center;
  color: #fff;
  ${p => p.isLoading && "margin-bottom:-4px"}
`;

const Left = styled.div`
  flex: 1;

  & > label {
    font-size: 1rem;
    padding: 0.25em 0.25em 0.25em 0em;
    display: inline-block;
    vertical-align: middle;
  }
`;
