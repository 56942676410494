import React from 'react';
import { useEffect } from 'react';
import fetchAsync, { FetchError } from '../utilities/fetch';

export type PaymentCompleteType = {
  orderId: number;
  transactionId: string;
  subtotal: number;
  taxes: number;
  total: number;
  monerisTicket: string;
};

export type Props = {
  createTransactionData: object;
  onComplete(paymentData: PaymentCompleteType): void;
  onCancel(): void;
  onError(error: FetchError): void;
  createTransactionUrl: string;
  cancelTransactionUrl: string;
  processPaymentUrl: string;
  activated: boolean;
  getAuthToken: () => Promise<{
    Authorization: string;
  }>;
};

const MonerisChecoutPanel = (props: Props) => {
  const {
    createTransactionData,
    onComplete,
    activated,
    onCancel,
    onError,
    getAuthToken,
    createTransactionUrl,
    cancelTransactionUrl,
    processPaymentUrl,
  } = props;

  useEffect(() => {
    if (activated) {
      getAuthToken().then(token => {
        fetchAsync<{ monerisTicket: string }>(
          createTransactionUrl,
          createTransactionData,
          {
            headers: token,
          }
        ).then(response => {
          if (response instanceof FetchError) {
            //TODO: Handle Error
            console.log('ERROR');
          } else {
            var json = response;

            CheoutViaMoneris({
              ticket: json.monerisTicket,
              monerisDivId: 'moneris_div',
              onPaymentComplete: (_, closeCheckout) => {
                //TODO: See if there is anything we can do with responseCode
                fetchAsync<PaymentCompleteType>(
                  processPaymentUrl,
                  {
                    monerisTicket: json.monerisTicket,
                  },
                  {
                    headers: token,
                  }
                ).then(receiptResponse => {
                  closeCheckout();
                  if (receiptResponse instanceof FetchError) {
                    onError(receiptResponse);
                  } else {
                    onComplete(receiptResponse);
                  }
                });
              },
              onTransactionCancelled: () => {
                console.log('Transaction Cancelled');

                if (activated) {
                  fetchAsync<{ monerisTicket: string }>(
                    cancelTransactionUrl,
                    { monerisTicket: json.monerisTicket },
                    {
                      headers: token,
                    }
                  );
                  onCancel();
                }
              },
              onTransactionError: e => {
                console.log('Transaction Error');
                onError(new FetchError(e, '', 1002));
              },
            });
          }
        });
      });
    }
  }, [activated, onCancel, onComplete, onError, createTransactionData]);

  return (
    <>
      <div id="moneris_div" style={{ zIndex: 1200 }}></div>
    </>
  );
};

export function CheoutViaMoneris(props: {
  ticket: string;
  monerisDivId: string;
  onPageLoaded?(data: string): void;
  onTransactionCancelled?(data: string): void;
  onTransactionError?(data: string): void;
  onPaymentReceipt?(data: string): void;
  onPaymentSubmitted?(data: string): void;
  onPaymentComplete(responseCode: string, closeCheckout: () => void): void;
}) {
  const {
    ticket,
    monerisDivId,
    onPageLoaded,
    onTransactionCancelled,
    onTransactionError,
    onPaymentComplete,
    onPaymentSubmitted,
    onPaymentReceipt,
  } = props;

  // @ts-ignore
  var myCheckout = new monerisCheckout();

  myCheckout.setMode('qa');
  myCheckout.setCheckoutDiv(monerisDivId);
  myCheckout.startCheckout(props.ticket);

  myCheckout.setCallback('page_loaded', onPageLoaded);

  myCheckout.setCallback('cancel_transaction', (e: any) => {
    console.log(`cancel_transaction ${e}`);

    myCheckout.closeCheckout(ticket);
    onTransactionCancelled?.(e);
  });

  myCheckout.setCallback('error_event', (e: any) => {
    console.log(`error_event ${e}`);
    myCheckout.closeCheckout(ticket);
    onTransactionError?.(e);
  });

  myCheckout.setCallback('payment_submitted', (e: any) => {
    console.log(`payment_submitted ${e}`);
    onPaymentSubmitted?.(e);
  });

  myCheckout.setCallback('page_closed', (e: any) => {
    console.log(`page_closed ${e}`);
  });

  myCheckout.setCallback('payment_receipt', (e: any) => {
    console.log(`payment_receipt ${e}`);
    onPaymentReceipt?.(e);
  }); //this will be triggered if Moneris receipt functionality is used/configured

  myCheckout.setCallback('payment_complete', (dataJson: string) => {
    const data: {
      ticket: string;
      response_code: string;
      handler: string;
    } = JSON.parse(dataJson);

    const { response_code } = data;

    console.log('payment_complete ' + dataJson);

    onPaymentComplete(response_code, () => {
      myCheckout.closeCheckout(ticket);
    });
  });
}

export default MonerisChecoutPanel;
